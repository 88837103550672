import { createContext, useContext, useEffect, useState } from 'react'
import { useController } from 'rest-hooks'
import { FullScreenLoader } from 'src/sdk/components/loader'
import { usePrivateConfig, usePublicConfig } from 'src/sdk/contexts/Config'
import { useNativeApp } from 'src/sdk/contexts/NativeApp'
import { GlobalNetworkCompany } from '../../sdk/datasource/globalNetwork'

type GlobalNetworkProps = {
  currentNetwork: GlobalNetworkCompany | undefined
  changeNetwork: (id: number | GlobalNetworkCompany) => void
  changeHomeHouse: (id: number | GlobalNetworkCompany) => void
  globalNetworks?: GlobalNetworkCompany[]
  setGlobalNetworks: (networks: GlobalNetworkCompany[]) => void
  globalNetworkEnabled?: boolean
  globalNetworkId?: number
  homeHouse?: number
}

const defaultValues: GlobalNetworkProps = {
  currentNetwork: new GlobalNetworkCompany(),
  changeNetwork: () => {},
  changeHomeHouse: () => {},
  globalNetworks: [],
  setGlobalNetworks: () => {},
  globalNetworkEnabled: false,
  globalNetworkId: undefined,
  homeHouse: undefined,
}

const GlobalNetworkContext = createContext<GlobalNetworkProps>(defaultValues)

const GlobalNetwork = ({ children }) => {
  const { fetch } = useController()
  const { dispatch, subscribe, unsubscribe, log, isNativeApp } = useNativeApp()
  const { company } = usePrivateConfig()
  const { globalNetwork } = usePublicConfig()
  const [switching, setSwitching] = useState(false)
  const [homeHouse, setHomeHouse] = useState<number>()
  const [networks, setNetworks] = useState<GlobalNetworkCompany[]>()
  const [currentNetwork, setCurrentNetwork] = useState<GlobalNetworkCompany | undefined>()

  const resolveNetwork = (network: number | GlobalNetworkCompany) => {
    let globalNetwork: GlobalNetworkCompany | undefined

    if (typeof network === 'number') {
      globalNetwork = networks?.find((n) => n.id === network)
    } else {
      globalNetwork = network
    }
    return globalNetwork
  }

  const changeNetwork = (request: number | GlobalNetworkCompany) => {
    let network = resolveNetwork(request)
    if (network) {
      setSwitching(true)

      if (isNativeApp) {
        dispatch({
          type: 'CHANGE_NETWORK',
          body: {
            url: network.portal.url,
          },
        })
      } else {
        window.location.href = `/global-switch/${network.portal?.url}`
      }
    }
  }

  const changeHomeHouse = (request: number | GlobalNetworkCompany) => {
    let network = resolveNetwork(request)
    if (network) {
      dispatch({
        type: 'HOME_HOUSE',
        body: {
          id: network.id,
          url: network.portal?.url,
        },
      })
      setHomeHouse(network.id)
      if (network.id !== company?.id) {
        changeNetwork(network)
      }
    }
  }

  const setGlobalNetworks = (globalNetworks: GlobalNetworkCompany[]) => {
    if (!globalNetwork?.enabled) return
    setNetworks(globalNetworks?.filter((n) => n.portal?.version === 'Version4'))
  }

  useEffect(() => {
    if (!networks) return

    if (networks.length === 1) {
      setCurrentNetwork(networks[0])

      // Ensure user goes to own house on mobile, where they logged in from the default company
      if (!homeHouse) {
        changeHomeHouse(networks[0])
      }
      return
    }

    if (!currentNetwork) {
      const network = networks?.find((n) => n.id === company?.id)
      if (network) {
        setCurrentNetwork(network)
      }
    }
  }, [networks])

  useEffect(() => {
    if (!isNativeApp) return
    const id = subscribe({
      event: 'HOME_HOUSE',
      callback: (homeHouse: { url: string; id: number }) => {
        setHomeHouse(homeHouse.id)
        if (homeHouse.id !== currentNetwork?.id) {
          changeNetwork(homeHouse.id)
        }
      },
    })
    dispatch({ type: 'FETCH_HOUSE' })
    return () => {
      unsubscribe(id)
    }
  }, [])

  return (
    <GlobalNetworkContext.Provider
      value={{
        homeHouse,
        currentNetwork,
        changeNetwork: changeNetwork,
        changeHomeHouse: changeHomeHouse,
        globalNetworks: networks,
        setGlobalNetworks: setGlobalNetworks,
        globalNetworkEnabled: globalNetwork?.enabled,
        globalNetworkId: globalNetwork?.id,
      }}
    >
      <FullScreenLoader loading={switching} />
      {children}
    </GlobalNetworkContext.Provider>
  )
}

const useGlobalNetwork: () => GlobalNetworkProps = () => useContext(GlobalNetworkContext)

export { GlobalNetwork, useGlobalNetwork }
